import * as React from "react"
import { Link } from "gatsby"
import LanguagePicker from "../components/languagepicker"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from 'react-i18next'
import { StaticImage } from "gatsby-plugin-image"
import { motion, useTransform, useScroll } from "framer-motion"
import ServiceCanvas from "../components/canvasservice"
import {scrollTo} from "../components/utils/scrollTo";
import i18next from "i18next"
import ContactCircleDynamic from "../components/contactcircle"
import * as styles from "../styles/service.module.scss"
import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink"
import { localizeString } from "../components/utils/localization"
import { DesktopOnlyMotion, DesktopOnlyMotionLi, DesktopOnlyMotionUl } from "../components/utils/motionHelper"

let previous = 0;
let refindex = 0;

//Variants
const elementTitle = {
    visible: { y:0,  opacity:1, transition: {ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0.1 } },
    hidden: { y:-10, opacity:0 },
}
const elementText = {
    visible: { y:0, opacity:1, transition: {ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0} },
    hidden: { y:90, opacity:0 },
}
const expandingcircle = {
  visible: { scale:2, opacity:1, transition: {ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0} },
  hidden: { scale:1, opacity:0 },
}
const list = {
    visible: { opacity: 1, transition: {ease: [0.215, 0.61, 0.355, 1], duration: 0.5, staggerChildren:0.1, delayChildren:0.5} },
    hidden: {  opacity: 0 },
  }
  const listitem = {
    visible: { y: 0, opacity: 1, transition: {ease: [0.215, 0.61, 0.355, 1], duration: 0.5} },
    hidden: { y: 90, opacity: 0 },
  }
  const readmore = {
    visible: { x:0, opacity:1, transition: {ease: [0.65, 0, 0.35, 1], duration: 1.1, delay: 0.7 } },
    hidden: { x:-50, opacity:0 },
}

function Services() {

    const { t, i18n } = useTranslation();
    const isGerman= i18next.language == 'de'? true : false;
    


    //ScrollToElements

    const ref0 = React.useRef(0);
    const ref1 = React.useRef(0);
    const ref2 = React.useRef(0);
    const ref3 = React.useRef(0);
    const ref4 = React.useRef(0);
    const ref5 = React.useRef(0);
    const getElementPosition = (element) => {
      
      if (element)
      {
        return element.offsetTop;

      }

      return 0;
    }

    const [isDark, setDark] = React.useState(false);
    const [isMobile, setisMobile] = React.useState(false);

    const { scrollYProgress } = useScroll();

    //Canvas
    const canvasY = useTransform(scrollYProgress, [0, 0.2], [1, 200]);

    //LanguagePicker Animation
const langY = useTransform(scrollYProgress,[0,0.075,0.2,0.3], [1,1,200,600])

    React.useEffect(() => scrollYProgress.onChange(latest => {
        //Set Page to dark
      //Mobile
      if(window.innerWidth < 1200)
      {   
        setDark(false);

     
      }
      //Desktop
      if(window.innerWidth >= 1200)
      {
        
        if (scrollYProgress.current>0.125 && scrollYProgress.current < 0.825)
        {
          setDark(true);
      
        }
        else 
        {
          setDark(false);
        }

        
        if(refindex === 0)
        {
          if(previous-(window.scrollY)<-100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 1; }, 1000);                
            scrollTo({ref:ref1, duration:1000});
            previous = getElementPosition(ref1.current);

          }
        }
        else if(refindex === 1)
        {
          if(previous-(window.scrollY)<-100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 2; }, 1000);                
            scrollTo({ref:ref2, duration:1000});
            previous = getElementPosition(ref2.current);

          }
          else if(previous-(window.scrollY)>100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 0; }, 1000);                
            scrollTo({ref:ref0, duration:1000});
            previous = getElementPosition(ref0.current);

          }
        }
        else if(refindex === 2)
        {
          if(previous-(window.scrollY)<-100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 3; }, 1000);                
            scrollTo({ref:ref3, duration:1000});
            previous = getElementPosition(ref3.current);

          }
          else if(previous-(window.scrollY)>100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 1; }, 1000);                
            scrollTo({ref:ref1, duration:1000});
            previous = getElementPosition(ref1.current);

          }
        }
        else if(refindex === 3)
        {
          if(previous-(window.scrollY)<-100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 4; }, 1000);                
            scrollTo({ref:ref4, duration:1000});
            previous = getElementPosition(ref4.current);

          }
          else if(previous-(window.scrollY)>100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 2; }, 1000);                
            scrollTo({ref:ref2, duration:1000});
            previous = getElementPosition(ref2.current);

          }
        }
        else if(refindex === 4)
        {
          if(previous-(window.scrollY)<-100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 5; }, 1000);                
            scrollTo({ref:ref5, duration:1000});
            previous = getElementPosition(ref5.current);

          }
          else if(previous-(window.scrollY)>100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 3; }, 1000);                
            scrollTo({ref:ref3, duration:1000});
            previous = getElementPosition(ref3.current);

          }
        }
        else if(refindex === 5)
        {
          if(previous-(window.scrollY)>100)
          {
            refindex = 10;
            setTimeout(function() {refindex = 4; }, 1000);                
            scrollTo({ref:ref4, duration:1000});
            previous = getElementPosition(ref4.current);

          }
        }

      
      
      }

  
      }), []);

      //Init
    React.useEffect(() => {

    function handleResize() {
      if(window.innerWidth >= 1200)
      {
        setisMobile(false);
      }
      else{
        setisMobile(true);
          }

    }
  
  // Add event listener
  window.addEventListener("resize", handleResize);
  // Call handler right away so state gets updated with initial window size
  handleResize();
  // Remove event listener on cleanup
  return () => window.removeEventListener("resize", handleResize);
  }, []) 

    return (
        <Layout type = "services" isDark={isDark} isMobile={isMobile} key={i18n.language}>
            { isMobile?
            <></>
            :
            <motion.div className="canvas-container" initial={{opacity:0}} animate={{opacity:1}} transition= {{ ease: "easeOut", duration: 0.5, delay:1 }}><motion.div style={{y: canvasY}}><ServiceCanvas/></motion.div></motion.div> 
             }

            <section ref={ref0} className={isMobile? "subpage-title-section dark" : "subpage-title-section light"} id="service-title-section">
                <h1 className="subpage-title">
                  <motion.div initial={{x:-90, opacity:0}} animate={{x:0, opacity:1}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0 }}>  <span className="subpage-title-bottom">{t("servicetitle1")} </span><br></br></motion.div>
                  <motion.div initial={{x:90, opacity:0}} animate={{x:0, opacity:1}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0 }}>     <span className="subpage-title-middle">{t("servicetitle2")}</span><br></br></motion.div>
                  <motion.div initial={{x:-90, opacity:0}} animate={{x:0, opacity:1}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0 }}>     <span className="subpage-title-bottom">{t("servicetitle3")}</span><br></br></motion.div>
                </h1>
                <motion.div initial={{x:90, opacity:0}} animate={{x:0, opacity:1}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.1, delay:0.25 }}> 
                <div className= {isDark?"subpage-subtitle light":"subpage-subtitle dark"}>
                    <hr></hr><span>{t("ourservice")}</span>
                </div>
                </motion.div>
                <motion.div initial={{y:50, opacity:0}} animate={{y:0, opacity:1}} transition= {{ ease: "easeOut", duration: 1.5, delay:1 }} className="lang-picker">
                    <motion.div style={isMobile?{display:"none"}:{y:langY}}><LanguagePicker isdark={isDark}></LanguagePicker></motion.div>
                </motion.div>  
                
            </section>
            { isMobile?
                <div className={styles.mobileImage}>
                <StaticImage src="../images/retrobw.jpg" alt={isGerman? "Schwarz-Weiß-Foto einer Straße in einer Großstadt" : "Black-and-white photograph of a street in a big city"}></StaticImage>
                </div>
            : 
                <></>
            }
            <section className={isDark? "service-section light" : "service-section dark"}>
                <div ref={ref1} className="service-element" id="service-strategy">
                    <div className="col-1">
                    <div className={styles.expandingCircleBox}><DesktopOnlyMotion variants={expandingcircle} initial="hidden" whileInView="visible" className={styles.expandingCircle}></DesktopOnlyMotion><span className={styles.serviceNumber} style={isMobile?{}:{left:"4px"}}>1</span></div>             
                        <DesktopOnlyMotion variants={elementTitle} initial="hidden" whileInView="visible">  
                          <h2 className={styles.serviceTitle}>{t("strategy")}</h2> 
                        </DesktopOnlyMotion>
                        <motion.hr initial={{width:0}} whileInView={{width:"100%"}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.7, delay:0 }}></motion.hr>
                        <div className="service-textbox flex">
                          <div className='col-1'>
                            <DesktopOnlyMotion variants={elementText} initial="hidden" whileInView="visible"> 
                            <p>{t("strategytext")} </p>
                            </DesktopOnlyMotion>
                            <DesktopOnlyMotion variants={readmore} initial="hidden" whileInView="visible" className="view-all"> 
                            <div className="view-all-links">
                                <Link to={localizeString("/projects")} className={isDark? "desktop-link light arrow-right-more" : "desktop-link dark arrow-right-more"}>{t("viewourwork")}<svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
                            </div>
                            </DesktopOnlyMotion>
                          </div>   
                        <div className='col-2'>
                        <DesktopOnlyMotionUl initial="hidden" whileInView="visible" variants={list} className="service-item-list">
                            <DesktopOnlyMotionLi key={"li-s1"} variants={listitem} className="service-item">{t("userresearch")}</DesktopOnlyMotionLi>
                            <DesktopOnlyMotionLi key={"li-s2"} variants={listitem} className="service-item">prototyping</DesktopOnlyMotionLi>
                            <DesktopOnlyMotionLi key={"li-s3"} variants={listitem} className="service-item">user journeys</DesktopOnlyMotionLi>
                            <DesktopOnlyMotionLi key={"li-s4"} variants={listitem} className="service-item">{t("validation")}</DesktopOnlyMotionLi>
                            <DesktopOnlyMotionLi key={"li-s5"} variants={listitem} className="service-item">{t("visualization")}</DesktopOnlyMotionLi>
                        </DesktopOnlyMotionUl>
                        <DesktopOnlyMotion variants={readmore} initial="hidden" whileInView="visible" className="view-all"> 
                        <div className="view-all-links">
                            <Link to={localizeString("/projects")} className={isDark? "mobile-link light arrow-right-more" : "mobile-link dark arrow-right-more" }>{t("viewourwork") }<svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
                        </div>
                        </DesktopOnlyMotion>    
                        </div>
                       </div>
                    </div>
                </div>
                <div ref={ref2} className="service-element flex" id="service-design">
                    <div className="col-1">
                    <div className={styles.expandingCircleBox}><DesktopOnlyMotion variants={expandingcircle} initial="hidden" whileInView="visible" className={styles.expandingCircle}></DesktopOnlyMotion><span className={styles.serviceNumber}>2</span></div>
                        <DesktopOnlyMotion variants={elementTitle} initial="hidden" whileInView="visible">  
                          <h2 className={styles.serviceTitle}>Design</h2> 
                        </DesktopOnlyMotion>
                        <motion.hr initial={{width:0}} whileInView={{width:"100%"}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.7, delay:0 }}></motion.hr>
                        <div className="service-textbox flex">
                          <div className='col-1'>
                            <DesktopOnlyMotion variants={elementText} initial="hidden" whileInView="visible"> 
                            <p>{t("designtext")}</p>
                            </DesktopOnlyMotion>
                            <DesktopOnlyMotion variants={readmore} initial="hidden" whileInView="visible" className="view-all"> 
                            <div className="view-all-links">
                                <Link to={localizeString("/projects")} className={isDark? "desktop-link light arrow-right-more" : "desktop-link dark arrow-right-more"}>{t("viewourwork")}<svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
                            </div>
                            </DesktopOnlyMotion>   
                          </div>
                          <div className="col-2">
                            <DesktopOnlyMotionUl initial="hidden" whileInView="visible" variants={list} className="service-item-list">
                            <DesktopOnlyMotionLi key={"li-d1"} variants={listitem} className="service-item">brand guidelines</DesktopOnlyMotionLi>
                            <DesktopOnlyMotionLi key={"li-d2"} variants={listitem} className="service-item">logo design</DesktopOnlyMotionLi>
                            <DesktopOnlyMotionLi key={"li-d3"} variants={listitem} className="service-item">UI/UX design</DesktopOnlyMotionLi>
                            <DesktopOnlyMotionLi key={"li-d4"} variants={listitem} className="service-item">animation</DesktopOnlyMotionLi>
                            <DesktopOnlyMotionLi key={"li-d5"} variants={listitem} className="service-item">{t("responsivedesign")}</DesktopOnlyMotionLi>
                        </DesktopOnlyMotionUl>
                        <DesktopOnlyMotion variants={readmore} initial="hidden" whileInView="visible" className="view-all"> 
                        <div className="view-all-links">
                            <Link to={localizeString("/projects")} className={isDark? "mobile-link light arrow-right-more" : "mobile-link dark arrow-right-more"}>{t("viewourwork")}<svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
                        </div>
                        </DesktopOnlyMotion>    
                        </div>                
                        </div>               
                      </div>
                    
                </div>
                <div ref={ref3} className="service-element flex" id="service-development">
                    <div className="col-1">
                    <div className={styles.expandingCircleBox}><DesktopOnlyMotion variants={expandingcircle} initial="hidden" whileInView="visible" className={styles.expandingCircle}></DesktopOnlyMotion><span className={styles.serviceNumber}>3</span></div>
                        <DesktopOnlyMotion variants={elementTitle} initial="hidden" whileInView="visible">  
                          <h2 className={styles.serviceTitle}>Development</h2> 
                        </DesktopOnlyMotion>
                        <motion.hr initial={{width:0}} whileInView={{width:"100%"}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.7, delay:0 }}></motion.hr>
                        <div className="service-textbox flex">
                          <div className='col-1'>
                            <DesktopOnlyMotion variants={elementText} initial="hidden" whileInView="visible"> 
                            <p>{t("developmenttext")}</p>
                            </DesktopOnlyMotion>
                            <DesktopOnlyMotion variants={readmore} initial="hidden" whileInView="visible" className="view-all"> 
                            <div className="view-all-links">
                                <Link to={localizeString("/projects")} className={isDark? "desktop-link light arrow-right-more" : "desktop-link dark arrow-right-more"}>{t("viewourwork")}<svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
                            </div>
                            </DesktopOnlyMotion>
                            </div>                    
                        
                        <div className="col-2">
                          <DesktopOnlyMotionUl initial="hidden" whileInView="visible" variants={list} className="service-item-list">
                              <DesktopOnlyMotionLi key={"li-dev1"} variants={listitem} className="service-item">Landing Pages</DesktopOnlyMotionLi>
                              <DesktopOnlyMotionLi key={"li-dev2"} variants={listitem} className="service-item">{t("webapplications")}</DesktopOnlyMotionLi>
                              <DesktopOnlyMotionLi key={"li-dev3"} variants={listitem} className="service-item">e-Commerce</DesktopOnlyMotionLi>
                              <DesktopOnlyMotionLi key={"li-dev4"} variants={listitem} className="service-item">{t("mobileapps")}</DesktopOnlyMotionLi>
                              <DesktopOnlyMotionLi key={"li-dev5"} variants={listitem} className="service-item">{t("frontend")}</DesktopOnlyMotionLi>
                          </DesktopOnlyMotionUl>
                          <DesktopOnlyMotion variants={readmore} initial="hidden" whileInView="visible" className="view-all"> 
                          <div className="view-all-links">
                              <Link to={localizeString("/projects")} className={isDark? "mobile-link light arrow-right-more" : "mobile-link dark arrow-right-more"}>{t("viewourwork")}<svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
                          </div>
                          </DesktopOnlyMotion>
                        </div>
                      </div>
                  </div>
                </div>
                <div ref={ref4} className="service-element flex" id="service-maintenance">
                    <div className="col-1">
                    <div className={styles.expandingCircleBox}><DesktopOnlyMotion variants={expandingcircle} initial="hidden" whileInView="visible" className={styles.expandingCircle}></DesktopOnlyMotion><span className={styles.serviceNumber}>4</span></div>
                        <DesktopOnlyMotion variants={elementTitle} initial="hidden" whileInView="visible">  
                          <h2 className={styles.serviceTitle}>Maintenance</h2> 
                        </DesktopOnlyMotion>
                        <motion.hr initial={{width:0}} whileInView={{width:"100%"}} transition= {{ ease: [0.65, 0, 0.35, 1], duration: 1.7, delay:0 }}></motion.hr>
                        <div className="service-textbox flex">
                          <div className='col-1'>
                            <DesktopOnlyMotion variants={elementText} initial="hidden" whileInView="visible"> 
                            <p>{t("maintenancetext")}</p>
                            </DesktopOnlyMotion>
                            <DesktopOnlyMotion variants={readmore} initial="hidden" whileInView="visible" className="view-all"> 
                            <div className="view-all-links">
                                <Link to={localizeString("/projects")} className={isDark? "desktop-link light arrow-right-more" : "desktop-link dark arrow-right-more"}>{t("viewourwork")}<svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
                            </div>
                            </DesktopOnlyMotion>                    
                        </div>
                        <div className="col-2">
                        <DesktopOnlyMotionUl initial="hidden" whileInView="visible" variants={list} className="service-item-list">
                                <DesktopOnlyMotionLi key={"li-m1"} variants={listitem} className="service-item">hosting</DesktopOnlyMotionLi>
                                <DesktopOnlyMotionLi key={"li-m2"} variants={listitem} className="service-item">technical support</DesktopOnlyMotionLi>
                                <DesktopOnlyMotionLi key={"li-m3"} variants={listitem} className="service-item">content updates</DesktopOnlyMotionLi>
                                <DesktopOnlyMotionLi key={"li-m4"} variants={listitem} className="service-item">security</DesktopOnlyMotionLi>
                                <DesktopOnlyMotionLi key={"li-m5"} variants={listitem} className="service-item">SEO optimization</DesktopOnlyMotionLi>
                            </DesktopOnlyMotionUl>
                            <DesktopOnlyMotion variants={readmore} initial="hidden" whileInView="visible" className="view-all"> 
                            <div className="view-all-links">
                                <Link to={localizeString("/projects")} className={isDark? "mobile-link light arrow-right-more" : "mobile-link dark arrow-right-more"}>{t("viewourwork")}<svg height="20" width="58"  alt="right-pointing arrow"><path d="m 0 10 l 56 0 m -6 6 l 6 -6 l -6 -6" stroke={isDark?"#EEEBEB":"#363636"} strokeWidth="1.7" fill="none"></path></svg><span className={isDark?"light":"dark"}></span></Link>
                            </div>
                            </DesktopOnlyMotion>                    
                        </div>
                      </div>
                    </div>
                </div>
          
            </section>
            <section ref={ref5} className={isDark? "cta-service light" : "cta-service dark"}>

                <h3 className="cta-heading">{t("serviceendmessage1")}<i>{t("serviceendmessage2")}</i><br></br>{t("serviceendmessage3")}</h3>
                <div className={styles.ctaButton}>
                  <DesktopOnlyMotion initial={{opacity:0}} animate={{opacity:1}} transition= {{ ease: "easeOut", duration: 0.6, delay:1.5 }} >
                    <TransitionLink exit={{length:1}} entry={{delay: 1}} to={localizeString("/contact")}><ContactCircleDynamic className={styles.ctaRotating}/></TransitionLink>
                  </DesktopOnlyMotion>
                </div>
            </section>


        </Layout>
    )
}
  
  export default Services
  
  export const Head = () => (
    <Seo Seo title="Our Services" titleDE="Unser Service" 
    description="If you're looking for branding, design, web development or general problem solving for great UX, we got you covered. Here is an overview of what we offer."
    descriptionDE="Egal ob Branding, Design, Web-Development oder SEO Services, wir begleiten ihr Projekt und stellen einzigartiges UX-Design sicher." /> )