import { motion } from "framer-motion"
import * as React from "react"



const DesktopOnlyMotion = ({children, ...props}) => {
    
    const [hasMounted, setHasMounted] = React.useState(false);
    
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    
    
    if (!hasMounted) {
      return null;
    }


    if(window.innerWidth >= 1200)
    {
        return(
            <motion.div {...props}>{children}</motion.div>    
            )
    }
    return(
        <div className={props.className? props.className : undefined} id={props.id? props.id : undefined} style={{}}>{children}</div>
    )
  
}

const DesktopOnlyMotionUl = ({children, ...props}) => {
    if(window.innerWidth >= 1200)
    {
        return(
            <motion.ul {...props}>{children}</motion.ul>    
            )
    }
    return(
        <ul className={props.className? props.className : undefined} id={props.id? props.id : undefined} aria-label={props.arialabel? props.arialabel : undefined} style={{}}>{children}</ul>
    )
  
}

const DesktopOnlyMotionLi = ({children, ...props}) => {
    if(window.innerWidth >= 1200)
    {
        return(
            <motion.li {...props}>{children}</motion.li>    
            )
    }
    return(
        <li className={props.className? props.className : undefined} id={props.id? props.id : undefined} aria-label={props.arialabel? props.arialabel : undefined} style={{}}>{children}</li>
    )
  
}


export { DesktopOnlyMotion, DesktopOnlyMotionUl, DesktopOnlyMotionLi }