import * as THREE from 'three';
import React from 'react';
import vertexShader from '../components/shaders/vertexShader.glsl';
import fragmentShader from '../components/shaders/fragmentShader.glsl';
import myimage from '../images/retrobw.webp';



let xcurrent = 0;
let xtarget = 0;
let ycurrent = 0;
let ytarget = 0;
let ease = 0.045;
let usedimage = myimage;

function lerp(start,end, t){
  return start * (1-t) + end * t;
}

function startLerp(){

  xcurrent = lerp(xcurrent, xtarget, ease);
  ycurrent = lerp(ycurrent, ytarget, ease)
}

class ServiceCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.imageRef = React.createRef();
    this.images = [];
    this.meshItems = [];
    this.requestID = undefined;



  }
get viewport(){
  let width = window.innerWidth;
  let height = window.innerHeight;
  let aspectRatio = width / height;
  return {
    width,
    height,
    aspectRatio
  }
}

setupCamera()
{
  window.addEventListener('resize', this.onWindowResize.bind(this));
  window.addEventListener('mousemove', this._onMouseMove.bind(this));
  document.addEventListener('mouseenter', this._onMouseEnter.bind(this));

  this.scene = new THREE.Scene();

  //perspective camera
  let perspective = 1000;
  const fov = (180 * (2 * Math.atan(window.innerHeight / 2 / perspective))) / Math.PI;
  this.camera = new THREE.PerspectiveCamera(fov, this.viewport.aspectRatio, 1, 1000);
  this.camera.position.set(0,0, perspective);

  this.renderer = new THREE.WebGL1Renderer({antialias: true, alpha: true});
  this.renderer.setSize(this.viewport.width,this.viewport.height);
  this.renderer.setPixelRatio(window.devicePixelRatio);
  this.myRef.current.appendChild(this.renderer.domElement);

 
}

onWindowResize()
{
  let perspective = 1000;
  const fov = (180 * (2 * Math.atan(window.innerHeight / 2 / perspective))) / Math.PI;
  this.camera.fov = fov;
  this.camera.aspect = this.viewport.aspectRatio;
  this.camera.position.set(0,0, perspective);  
  this.camera.updateProjectionMatrix();
  this.renderer.setSize(this.viewport.width,this.viewport.height);
  this.renderer.setPixelRatio(window.devicePixelRatio);
}

createMeshItems()
{
  this.images = [];
  this.meshItems = [];
  this.images.push(this.imageRef.current);

  this.images.forEach(image =>{
    
    let meshItem = new MeshItem(image, this.scene);
    this.meshItems.push(meshItem);
  })
}

disposeMeshItems()
{
  

  this.meshItems.forEach(meshItem =>{
    
    meshItem.dispose();
  })
}

renderme()
{
 this.requestID = undefined;
  startLerp();
  for(let i=0; i< this.meshItems.length; i++){
      this.meshItems[i].render();
    }

    this.renderer.render(this.scene, this.camera);

    if (!this.requestID)
    {
      this.requestID = requestAnimationFrame(this.renderme.bind(this));
    }


   
    
}

_onMouseMove(e) {
 
  ytarget = e.screenY;
  xtarget = e.screenX;


 
}

_onMouseEnter(e) {
 
  ytarget = e.screenY;
  xtarget = e.screenX;
  xcurrent = xtarget;
  ycurrent = ytarget;

 
}



  componentDidMount() {
    this.setupCamera();
   this.createMeshItems();
    this.renderme();
  }

  componentWillUnmount()
  {
    cancelAnimationFrame(this.requestID);
    /*
    cancelAnimationFrame(requestID);
    this.renderer.forceContextLoss();
    this.renderer.dispose();
    this.disposeMeshItems();
*/


  }


  render(){
    
   

    return(
      <>
              <div ref={this.imageRef} src={usedimage} style={{position:"absolute", top:500, left:"-50%", visibility:"hidden", width:600, height:600}}></div>

        
        <div ref={this.myRef} className='three-container' >
      
      </div>
      </>
     

    )
 

  }



}

class MeshItem{
  constructor(element, scene){
    this.element = element;
    this.scene = scene;
    this.offset = new THREE.Vector2(0,0);
    this.sizes = new THREE.Vector2(0,0);
    this.createMesh();
  }

  getDimensions()
  {
    const {width, height, top, left} = this.element.getBoundingClientRect();
    this.sizes.set(width,height);
    this.offset.set(0, 0);
 
  }

  createMesh()
  {
    this.geometry = new THREE.PlaneBufferGeometry(1,1,100,100);
    this.imageTexture = new THREE.TextureLoader().load(usedimage);
    this.imageTexture.magFilter = THREE.NearestFilter;
    this.imageTexture.minFilter = THREE.NearestFilter;
    
    this.uniforms = {
      uTexture: {value: this.imageTexture},
      uOffset: {value: new THREE.Vector2(0.0,0.0)},
    
      perecent: {type: "f", value: 1.0}
    }
  
    this.material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      transparent:true,
      blending: THREE.AdditiveBlending,
      //wireframe: true,
      side: THREE.FrontSide
    }); 
    this.mesh = new THREE.Mesh( this.geometry, this.material );
 
    this.getDimensions(); // set offsetand sizes for placement on the scene
    this.mesh.position.set(this.offset.x, this.offset.y, 0);
    this.mesh.scale.set(this.sizes.x, this.sizes.y, 1);

    this.scene.add(this.mesh);
  }

  render()
  {
    this.getDimensions();
    this.mesh.position.set(this.offset.x, this.offset.y, 0)
    this.mesh.scale.set(this.sizes.x, this.sizes.y, 1)
    let mult = 0.00025 ///((xtarget-this.offset.x)*0.00003);
 

    this.uniforms.uOffset.value.set((xtarget- xcurrent) * mult , -(ytarget- ycurrent) * mult )
  }

  dispose()
  {
    this.geometry.dispose();
    this.material.dispose();
    this.imageTexture.dispose();
    this.scene.remove(this.mesh);

  }
}



export default ServiceCanvas;

